/* Animations */
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
/* Animations End */

/* Head Animations*/
@keyframes musing {
  0% { transform: translate(-3%,0) rotate(-5deg);}
  50% {transform: translate(3%,0) rotate(5deg);}
  100% {transform: translate(-3%,0) rotate(-5deg);}
}

@keyframes reading {
  0% {transform: translate(0,0);}
  30% {transform: translate(0,0) scale(1.05,1.05);}
  50% {transform: translate(2%,1%) scale(1.1,1.1) rotate(2.5deg);}
  70% {transform: translate(2%,1%) scale(1.1,1.1) rotate(2.5deg);}
  80% {transform: translate(2%,1%) scale(1.1,1.1) rotate(2.5deg);}
  100% {transform: translate(0,0);}
}

@keyframes thinking {
  0% {transform: translate(-50%,0);}
  20% {transform: translate(-50%,0);}
  50% {transform: translate(-50%, 0);}
  80% {transform: translate(-50%,10%);}
  100% {transform: translate(-50%,0);}
}
/* Head Animations End */

/* Eyes Animations */
@keyframes readingEyes {
  0% {transform: translate(0,0) scaleY(0);}
  5% {transform: scaleY(1);}
  30% {transform: translate(0,0);}
  40%, 60% {transform: translate(-1%,0);}
  50%, 70% {transform: translate(1.4%,0);}
  80% {transform: translate(-1%,0)}
  100% {transform: translate(0,0);}
}

@keyframes blink {
  0% {transform: scale(1, .05);}
  5% {transform: scale(1, 1);}
  95% {transform: scale(1, 1);}
  100% {transform: scale(1, .05);}
}
/* Eyes Animations End */

.eyes1 {
  transform-origin: 50% 11%;
  animation-name: blink;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-easing: ease-in-out;
}

.eyes2 {
  transform-origin: 50% 0;
  animation-name: readingEyes;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-easing: ease-in-out;
}

#head1 {
  transform-origin: 50% 0%;
  animation-name: musing;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-easing: ease;
}

#head2 {
  transform-origin: 50% 0%;
  animation-name: reading;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-easing: ease-in-out;
}

.speechBubble-right {
  display: none;
}

.speechBubble-right:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 20%;
	width: 0;
	height: 0;
	border: 2vmin solid transparent;
	border-top-color: #B4E7FF;
	border-bottom: 0;
	border-left: 0;
	margin-left: -1vmin;
  margin-bottom: -2vmin;
  animation: fadeIn ease 4s;
}

.speechBubble-left {
  display: none;
}

.speechBubble-left:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 80%;
	width: 0;
	height: 0;
	border: 2vmin solid transparent;
	border-top-color: #B4E7FF;
	border-bottom: 0;
	border-right: 0;
	margin-right: -1vmin;
  margin-bottom: -2vmin;
  animation: fadeIn ease 4s;
}

/*-----------------------------------------------------------------------------------*/
/*  Table SVG
/*-----------------------------------------------------------------------------------*/

.table-1 {
  fill: #84664e;
}

.table-2 {
  fill: #937458;
}

.table-3 {
  fill: #a08060;
}

.table-4 {
  fill: #a8886f;
}

.table-5 {
  fill: #8c6d56;
}

/*-----------------------------------------------------------------------------------*/
/*  Laptop SVG
/*-----------------------------------------------------------------------------------*/
.laptop1-1 {
  fill: #d8d8d8;
  stroke-width: 2px;
  stroke: url(#linear-gradient);
}

/*-----------------------------------------------------------------------------------*/
/*  Chair SVG
/*-----------------------------------------------------------------------------------*/
.chair1-1 {
  fill: #515151;
}

.chair1-2 {
  fill: #44515f;
}

.chair1-3 {
  fill: #282828;
}

/*-----------------------------------------------------------------------------------*/
/*  Skin SVG
/*-----------------------------------------------------------------------------------*/
.skin1-1 {
  fill: #f7a584;
}

.skin1-2 {
  fill: #feac86;
}

.skin1-3 {
  fill: #e0977e;
}

.skin1-4 {
  fill: #ea9c7d;
}

/*-----------------------------------------------------------------------------------*/
/*  Hair SVG
/*-----------------------------------------------------------------------------------*/

/* Hair 1 */
.hair1-1 {
  fill: #492a00;
}

/* Hair 2 */
.hair2-1 {
  fill: #2d1700;
}

/*-----------------------------------------------------------------------------------*/
/*  Shirt SVG
/*-----------------------------------------------------------------------------------*/

/* Shirt 1 */
.shirt1-1 {
  fill: #374c89;
}

/* Shirt 2 */
.shirt2-1 {
  fill: #388434;
}

/* Shirt 3 */
.shirt3-1 {
  fill: #d96627;
}

/* Shirt 4 */
.shirt4-1 {
  fill: #ece51a;
}

/*-----------------------------------------------------------------------------------*/
/*  Pants SVG
/*-----------------------------------------------------------------------------------*/

/* Pants 1 */
.pants1-1 {
  fill: #b56b2a;
}

.pants1-2 {
  fill: #c16f33;
}

/* Pants 2 */
.pants2-1 {
  fill: #3c3c56;
}

.pants2-2 {
  fill: #454560;
}

/* Pants 3 */
.pants3-1 {
  fill: #333333;
}

.pants3-2 {
  fill: #434343;
}

/* Pants 4 */
.pants4-1 {
  fill: #c4c4c4;
}

.pants4-2 {
  fill: #dedede;
}

/*-----------------------------------------------------------------------------------*/
/*  Shoes SVG
/*-----------------------------------------------------------------------------------*/

/* Shoes 1 */
.shoes1-1 {
  fill: #374c89;
}

.shoes1-2 {
  fill: #afafaf;
}

/* Shoes 2 */
.shoes2-1 {
  fill: #388434;
}

.shoes2-2 {
  fill: #afafaf;
}

/* Shoes 3 */
.shoes3-1 {
  fill: #d96627;
}

.shoes3-2 {
  fill: #afafaf;
}
/* Shoes 4 */
.shoes4-1 {
  fill: #ece51a;
}

.shoes4-2 {
  fill: #afafaf;
}
